import axios from 'axios';
import config from '@/config/env-constants';
import { remoteConfig } from '@/config/firebase';
import _ from 'lodash';

async function getAppVersion() {
  await remoteConfig.fetchAndActivate();
  const versionName = remoteConfig.getString("eLogMainVersionName");
  const versionCode = remoteConfig.getString("eLogMainVersionCode");
  return versionName + '.' + versionCode;
}

export default {
  baseUrl: `https://us-central1-elog-${config.currEnv === 'prod' ? '' : config.currEnv + '-'}app.cloudfunctions.net`,

  async checkin(params) {

    // Update Scan Logs Fields
    let scanLog = params.scanLog;
    if (!_.isEmpty(scanLog)) {
      let appVersion = await getAppVersion();
      scanLog.appVersion = appVersion;
      scanLog.scanId = `SL${scanLog.dateCreated}`;
      scanLog.otherUserCompany = null;
      scanLog.otherUserCompanyId = null;
      scanLog.otherUserId = null;
      scanLog.otherUserName = null;
      scanLog.requiredCheckOut = true;
      scanLog.isAutoCheckout = false;
      scanLog.isAutoUpdate = false;
      scanLog.isCheckIn = scanLog.isCheckIn;
      scanLog.isDeleted = false;
      scanLog.isOffline = false;
      scanLog.isPublic = false;
      scanLog.dateDeleted = null;
      scanLog.deletedBy = null;
    } else {
      scanLog = {};
    }

    // Update User Form Log Fields
    let userFormLog = params.userFormLog;
    if (!_.isEmpty(userFormLog) && !_.isEmpty(scanLog)) {
      userFormLog.scanId = scanLog.scanId;
      userFormLog.dateCreated = scanLog.dateCreated;
      userFormLog.createdBy = scanLog.createdBy;
    }

    let url = `${this.baseUrl}/userCheckIn`;
    return axios.post(url, {
      scanLog: scanLog,
      userFormLog: userFormLog
    });
  }
}