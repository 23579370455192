<template>
  <b-card class="card-panel" title="">
    <loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />
    <b-row>
      <b-col sm="6" md="6" lg="4">
        <b-form-group label="Date From" label-size="sm" label-class="font-weight-bold pt-0">
          <b-input id="dateFrom" type="date" v-model="filterBy.dateFrom" />
        </b-form-group>
      </b-col>
      <b-col sm="6" md="6" lg="4">
        <b-form-group label="Date To" label-size="sm" label-class="font-weight-bold pt-0">
          <b-input id="dateTo" type="date" v-model="filterBy.dateTo" />
        </b-form-group>
      </b-col>
      <b-col sm="12" md="12" lg="4" class="mt-4 mb-4 text-sm-left text-md-left">
        <b-button @click="onFilterRequest">
          <i class="fa fa-search" />
        </b-button>
        <b-button class="ml-1" @click="resetFilters">
          <i class="fa fa-sync" />
        </b-button>
      </b-col>
    </b-row>

    <b-row no-gutters>
      <b-col sm="6" md="6" class="mt-3 text-md-left mb-2">
        <b-dropdown text=" Select Actions " variant="dark" slot="append">
          <b-dropdown-item>
            <json-excel :data="exportData" :fields="exportFields" type="xls" :name="fileName + '.xls'">
              Export in Excel
            </json-excel>
          </b-dropdown-item>
          <b-dropdown-item>
            <json-excel :data="exportData" :fields="exportFields" type="csv" :name="fileName + '.csv'">
              Export to CSV
            </json-excel>
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
      <b-col sm="6" md="6" class="mt-3 text-md-right mb-2">
        <base-dropdown position="right">
          <base-button slot="title" type="secondary" class="dropdown-toggle">Show {{ perPage }} / Page</base-button>
          <b-dropdown-item v-for="(option, index) in pageOptions" :key="index" @click="perPage = option">
            {{ option }}
          </b-dropdown-item>
        </base-dropdown>
      </b-col>
    </b-row>

    <b-table :items="items" :fields="fields" selected-variant="primary" :current-page="currentPage" :per-page="perPage"
      striped responsive style="min-height: 300px">
      <template v-slot:cell(date)="row">
        {{ row.item._timein | dateFormat }}
        <br />

      </template>
      <template v-slot:cell(timeIn)="row">
        {{ row.item._timein | timeFormat }}
        <br />

      </template>
      <template v-slot:cell(timeout)="row">
        {{ row.item._timeout | timeFormat }}
      </template>
    </b-table>
    <template v-if="items.length === 0">
      <h6 class="text-center">There are no records to show</h6>
    </template>
    <b-row>
      <b-col md="6" sm="6">
        <span class="total-display">Total: {{ items.length }}</span>
      </b-col>
      <b-col md="6" sm="6">
        <b-pagination align="right" :total-rows="items.length" :per-page="perPage" v-model="currentPage" class="my-0" />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
// Util
import DateUtil from "../../utils/date";
import FilterUtil from "../../utils/filter";

// DAO & API
import reportApi from "@/api/report";

// Others
import { mapGetters } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from 'moment';
import JsonExcel from 'vue-json-excel';

export default {
  name: "check-in-history",
  components: {
    Loading,
    JsonExcel
  },
  data() {
    return {
      isLoading: false,
      items: [],
      fields: [
        { key: "date", label: "Date" },
        { key: "timeIn", label: "Check-In" },
        { key: "timeOut", label: "Check-Out" },
        { key: "totalHours", label: "Duration" },
      ],
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15],
      filterBy: {
        user: { id: "" },
        userCompany: { id: "" },
        hub: { id: "" },
        hubCompany: { id: "" },
        dateFrom: moment().format('YYYY-MM-DD'),
        dateTo: moment().format('YYYY-MM-DD'),
        orderBy: "desc",
      }
    };
  },
  computed: {
    ...mapGetters("worklog", ["getCurrWorkLog", "getCurrMainHub"]),

    /**
     * Returns the set of data to be included in the export. For now this just
     * returns the data as is.
     *
     * @returns {Array} the set of data to be included in the export.
     */
    exportData() {
      const exportedData = _.map(this.items, item => {
        const date = item._timein ? DateUtil.getFormattedDate(item._timein) : '-';
        const timeIn = item._timein ? DateUtil.getFormattedTime(item._timein) : '-';
        const timeOut = item._timeout ? DateUtil.getFormattedTime(item._timeout) : '-';

        return {
          'date': date,
          'timeIn': timeIn,
          'timeOut': timeOut,
          ...item
        };
      });

      return exportedData;
    },

    /**
     * Derives the field information based from the data table configuration.
     *
     * @returns {object} the fields to be included in the export.
     */
    exportFields() {
      return {
        'Date': 'date',
        'Check-in': 'timeIn',
        'Check-out': 'timeOut',
        'Duration': 'totalHours',
      };
    },

    fileName() {
      let currTimeStamp = DateUtil.getCurrentTimestamp();
      return 'Check-In-History-' + DateUtil.getDateInDDMMYYYYHHSSFormat(currTimeStamp);
    },
  },
  watch: {
    getCurrWorkLog: {
      handler() {
        this.resetFilters();
      },
      immediate: true,
    },
  },
  mounted() {
    this.filterBy.dateFrom = this.getDateFrom();
    this.filterBy.dateTo = this.getDateTo();
  },
  methods: {
    getDateFrom() {
      const dateTo = moment();
      const dateFrom = dateTo.add(-30, 'days');
      return dateFrom.format('YYYY-MM-DD');
    },
    getDateTo() {
      return moment().format('YYYY-MM-DD');
    },
    getFilterBy(filterBy) {
      filterBy.dateFrom = DateUtil.startDateTimeStamp(Date.parse(filterBy.dateFrom));
      filterBy.dateTo = DateUtil.endDateTimeStamp(Date.parse(filterBy.dateTo));
      filterBy.orderBy = "desc";
      return filterBy;
    },

    async onFilterRequest() {
      try {
        // show loading indicator
        this.isLoading = true;
        
        let filterBy = FilterUtil.getFilterBy(this.filterBy);
        const { data } = await reportApi.getDailyTimeRecord(filterBy);
        this.items = data.items.sort((a, b) => b._timein - a._timein);

      } catch (error) {
        this.$toast.error("There was a problem fetching time-in/time-out histories", "Oops!");
      }

      // hide loading indicator
      this.isLoading = false;
    },

    async resetFilters() {
      const user = this.$store.getters["auth/getCurrentUser"];
      this.filterBy.user.id = user.id;
      this.filterBy.userCompany.id = user.companyId;
      this.filterBy.hub.id = this.getCurrMainHub.id;
      this.filterBy.hubCompany.id = this.getCurrMainHub.companyId;
      this.filterBy.dateFrom = this.getDateFrom();
      this.filterBy.dateTo = this.getDateTo();

      await this.onFilterRequest();
    },
  },
};
</script>

<style scoped>
.total-display {
  font-weight: bold;
}
</style>