<template>
  <b-container fluid>
    <b-row class="mt-4" no-gutters>
      <b-col sm="10" offset-sm="1" class="mt-2 mb-4">
        <span class="section-question my-2"> Additional Questions </span>
      </b-col>

      <b-col sm="10" offset-sm="1">
        <div :key="QA.name" v-for="(QA, index) in QAs">
          <b-form-group :label="getLabel(QA)" label-size="sm">
            <b-col sm="6">
              <b-form-radio-group
                :id="'qa' + index"
                v-model="QA.answer"
                :options="options"
                :name="'qa' + index"
              ></b-form-radio-group>
            </b-col>

            <div v-if="index === 0 && QA.answer === 'YES'">
              <b-col sm="6" class="my-2">
                <b-form-input
                  type="text"
                  size="sm"
                  v-model="others1"
                  placeholder="Please specify"
                >
                </b-form-input>
              </b-col>
            </div>
            <div v-if="index === 3 && QA.answer === 'YES'">
              <b-col sm="6" class="my-2">
                <b-form-input
                  type="text"
                  size="sm"
                  v-model="others4"
                  placeholder="Please specify"
                  class="mt-1"
                >
                </b-form-input>
              </b-col>
            </div>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import EventBus from "@/shared/event-bus";
import _ from "lodash";

export default {
  name: "Step5",
  props: ["clickedNext", "currentStep"],
  mixins: [validationMixin],
  data() {
    return {
      form: {
        questionsAndAnswers: [],
      },
      others1: "",
      others4: "",

      options: [
        { text: "YES", value: "YES" },
        { text: "NO", value: "NO" },
      ],

      QAs: [
        {
          question: "Do you have pre-existing health conditions? ",
          caption:
            "(e.g. Hypertension, Diabetes, Asthma, Tuberculosis, Cancer, Pregnant)",
          answer: "",
        },
        {
          question: "Have you been in contact with a positive COVID-19 person?",
          answer: "",
        },
        {
          question:
            "Have you been in contact with someone having COVID-19 symptoms in the past two weeks?",
          answer: "",
        },
        {
          question: "Travelled outside the country in the last 14 days?",
          answer: "",
        },
        {
          question: "Travelled other areas outside of home/work?",
          answer: "",
        },
        {
          question: "Do you know how COVID-19 virus transmit?",
          answer: "",
        },
      ],
    };
  },
  watch: {
    others1: function (val) {
      if (this.QAs[0].answer === "YES" && _.isEmpty(this.others1)) {
        this.$emit("can-continue", { value: false });
      } else {
        this.reflectOnQAs();
      }
    },
    others4: function (val) {
      if (this.QAs[0].answer === "YES" && _.isEmpty(this.others4)) {
        this.$emit("can-continue", { value: false });
      } else {
        this.reflectOnQAs();
      }
    },
    QAs: {
      handler: function (val) {
        this.reflectOnQAs();
      },
      deep: true,
    },
    $v: {
      handler: function (val) {
        this.updateNextButton(!val.$invalid);
      },
      deep: true,
    },
    clickedNext(val) {
      if (val === true) {
        this.$v.form.$touch();
      }
    },
  },
  validations: {
    form: {
      questionsAndAnswers: {
        required,
      },
    },
  },
  mounted() {
    let vm = this;
    vm.updateNextButton(!this.$v.$invalid);

    EventBus.$on("reload-content-4", function () {
      vm.updateNextButton(!vm.$v.$invalid);
    });
  },
  methods: {
    getLabel(QA) {
      let label = QA.question;
      if (QA.caption) {
        label += QA.caption;
      }
      return label;
    },
    reflectOnQAs() {
      this.form.questionsAndAnswers = [];

      this.QAs.forEach((qa, index) => {
        if (!_.isEmpty(qa.answer)) {
          let answer = qa.answer;
          if (index === 0 && qa.answer === "YES" && !_.isEmpty(this.others1)) {
            qa.others = this.others1;
            answer += " - " + this.others1;
          }

          if (index === 3 && qa.answer === "YES" && !_.isEmpty(this.others4)) {
            // get value from others1
            qa.others = this.others4;
            answer += " - " + this.others4;
          }

          this.form.questionsAndAnswers.push({
            question: qa.question,
            answer: answer,
          });
        }
      });
    },
    updateNextButton(isValid) {
      if (isValid) {
        if (this.form.questionsAndAnswers.length !== 6) {
          this.$emit("can-continue", { value: false });
        } else {
          this.$emit("can-continue", { value: true });
          EventBus.$emit("form-update", this.form);
        }
      } else {
        this.$emit("can-continue", { value: false });
      }
    },
  },
};
</script>

<style scoped>
.section-question {
  font-weight: bold;
  font-size: 15px;
}
</style>
