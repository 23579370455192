<template>
  <div>
    <b-tabs content-class="mt-3">
      <b-tab title="Check-In History" active>
        <template #title>
          <span class="tab-title">Check-In History</span>
        </template>
        <CheckInHistory />
      </b-tab>

      <b-tab title="Health Declaration Form">
        <template #title>
          <span class="tab-title">Health Declaration Form</span>
        </template>
        <HealthDeclarationFormHistory />
      </b-tab>
    </b-tabs>
  </div>
</template>


<script>
import CheckInHistory from "./CheckInHistory";
import HealthDeclarationFormHistory from "./HealthDeclarationFormHistory";

export default {
  name: "History",
  components: {
    CheckInHistory,
    HealthDeclarationFormHistory,
  },
};
</script>

<style scoped>
.tab-title {
  font-size: 14px;
}
</style>
