<template>
  <div>
    <b-row no-gutters>
      <b-col sm="12">
        <b-form-group
          label="BODY TEMPERATURE CHECK"
          label-size="sm"
          label-class="font-weight-bold pt-0"
        >
          <div class="form-value">
            {{ `${row.item.form.bodyTemp} °C ${row.item.form.bodyTempStat}` }}
          </div>
        </b-form-group>
      </b-col>

      <b-col sm="12">
        <b-form-group
          label="MODE OF TRANSPORTATION"
          label-size="sm"
          label-class="font-weight-bold pt-0"
        >
          <div class="form-value">
            {{ row.item.form.modeOfTransportation }}
          </div>
        </b-form-group>
      </b-col>

      <b-col sm="12">
        <b-form-group
          label="PURPOSE OF VISIT"
          label-size="sm"
          label-class="font-weight-bold pt-0"
        >
          <div class="form-value">
            {{ row.item.form.purposeOfVisit }}
          </div>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="6">
        <b-form-group
          label="DO YOU HAVE ANY OF THE FOLLOWING SYMPTOMS?"
          label-size="sm"
          label-class="font-weight-bold pt-0"
        >
          <span class="form-value">
            <template v-if="row.item.form.symptoms.length > 0">
              <li
                v-for="(symptom, index) in row.item.form.symptoms"
                :key="index"
              >
                {{ symptom.replace(/_|-/g, " ") }}
              </li>
            </template>
            <span v-else>No symptoms</span>
          </span>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="6">
        <b-form-group
          label="ADDITIONAL QUESTIONS"
          label-size="sm"
          label-class="font-weight-bold pt-0"
        >
          <span class="form-value">
            <template
              v-for="(item, index) in row.item.form.questionsAndAnswers"
            >
              <li :key="index">
                {{ item.question }}
                <strong>{{ item.answer }}</strong>
              </li>
            </template>
          </span>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "health-declaration-form-details-view",
  components: {},
  props: {
    row: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      item: {},
    };
  },
  mounted() {
    this.item = this.row.item;
  },
  methods: {},
};
</script>

<style scoped>
.form-value {
  width: 150px;
  font-size: 12px;
  word-wrap: break-word;
}
</style>
