<template>
  <b-container fluid>
    <b-row class="mt-4" no-gutters>
      <b-col sm="6" offset-sm="1">
        <b-form-group label="Mode of Transportation">
          <b-form-checkbox
            class="my-1"
            v-model="option1"
            value="Public Transportation"
          >
            Public Transportation
          </b-form-checkbox>
          <b-form-checkbox
            class="my-1"
            v-model="option2"
            value="Personal Vehicle"
          >
            Personal Vehicle
          </b-form-checkbox>
          <b-form-checkbox
            class="my-1"
            v-model="option3"
            value="Company Vehicle"
          >
            Company Vehicle
          </b-form-checkbox>
          <b-form-checkbox class="my-1" v-model="option4" value="Walking">
            Walking
          </b-form-checkbox>
          <b-form-checkbox class="my-1" v-model="option5" value="Biking">
            Biking
          </b-form-checkbox>

          <b-row no-gutters>
            <b-col sm="6" md="4" class="mb-2">
              <b-form-checkbox v-model="option6" value="Others">
                Others
              </b-form-checkbox>
            </b-col>
            <b-col v-if="option6.length > 0" sm="12" md="6">
              <b-form-input
                type="text"
                size="sm"
                v-model="others"
                placeholder="Please specify"
              >
              </b-form-input>
            </b-col>
          </b-row>
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import EventBus from "@/shared/event-bus";
import _ from "lodash";

export default {
  name: "Step2",
  props: ["clickedNext", "currentStep"],
  mixins: [validationMixin],
  data() {
    return {
      form: {
        modeOfTransportation: "",
      },

      option1: "",
      option2: "",
      option3: "",
      option4: "",
      option5: "",
      option6: "",
      others: "",
    };
  },
  validations: {
    form: {
      modeOfTransportation: {
        required,
      },
    },
  },
  watch: {
    option1: function () {
      this.reflectOnTransportation();
    },
    option2: function () {
      this.reflectOnTransportation();
    },
    option3: function () {
      this.reflectOnTransportation();
    },
    option4: function () {
      this.reflectOnTransportation();
    },
    option5: function () {
      this.reflectOnTransportation();
    },
    option6: function () {
      if (!_.isEmpty(this.option6) && _.isEmpty(this.others)) {
        this.$emit("can-continue", { value: false });
      } else {
        this.reflectOnTransportation();
      }
    },
    others: function () {
      if (!_.isEmpty(this.option6) && _.isEmpty(this.others)) {
        this.$emit("can-continue", { value: false });
      } else {
        this.reflectOnTransportation();
      }
    },
    $v: {
      handler: function (val) {
        this.updateNextButton(!val.$invalid);
      },
      deep: true,
    },
    clickedNext(val) {
      if (val === true) {
        this.$v.form.$touch();
      }
    },
  },
  mounted() {
    let vm = this;
    vm.updateNextButton(!this.$v.$invalid);

    EventBus.$on("reload-content-1", function () {
      vm.updateNextButton(!vm.$v.$invalid);
    });
  },
  methods: {
    updateNextButton(isValid) {
      if (isValid) {
        if (!_.isEmpty(this.option6) && _.isEmpty(this.others)) {
          this.$emit("can-continue", { value: false });
        } else {
          this.$emit("can-continue", { value: true });
          EventBus.$emit("form-update", this.form);
        }
      } else {
        this.$emit("can-continue", { value: false });
      }
    },
    reflectOnTransportation() {
      let modeOfTransportation = [];
      if (!_.isEmpty(this.option1)) {
        modeOfTransportation.push(this.option1);
      }
      if (!_.isEmpty(this.option2)) {
        modeOfTransportation.push(this.option2);
      }
      if (!_.isEmpty(this.option3)) {
        modeOfTransportation.push(this.option3);
      }
      if (!_.isEmpty(this.option4)) {
        modeOfTransportation.push(this.option4);
      }
      if (!_.isEmpty(this.option5)) {
        modeOfTransportation.push(this.option5);
      }
      if (!_.isEmpty(this.option6)) {
        modeOfTransportation.push(this.others);
      }

      this.form.modeOfTransportation = modeOfTransportation.join();
    },
  },
};
</script>

<style scoped>
</style>
