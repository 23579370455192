<template>
  <b-container fluid>
    <b-row class="mt-4" no-gutters>
      <b-col sm="6" md="4" offset-sm="3" offset-md="1">
        <b-form-group label="Body Temperature Check">
          <b-input-group class="mb-2">
            <b-button text="Button" variant="success" @click="decreaseTemp">
              -
            </b-button>

            <b-form-input
              type="number"
              placeholder="Temperature"
              v-model="form.bodyTemp"
              :class="tempFormat"
            >
            </b-form-input>

            <b-button text="Button" variant="success" @click="increaseTemp">
              +
            </b-button>
          </b-input-group>

          <div :class="tempFormat">{{ form.bodyTempStat }}</div>
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import EventBus from "@/shared/event-bus";
import _ from "lodash";

export default {
  name: "Step1",
  props: ["clickedNext", "currentStep"],
  mixins: [validationMixin],
  data() {
    return {
      form: {
        bodyTemp: "36.5",
        bodyTempStat: "NORMAL",
      },
      tempFormat: "normal",
    };
  },
  validations: {
    form: {
      bodyTemp: {
        required,
      },
    },
  },
  watch: {
    "form.bodyTemp": function () {
      this.generateStatus();
    },
    $v: {
      handler: function (val) {
        this.updateNextButton(!val.$invalid);
      },
      deep: true,
    },
    clickedNext(val) {
      if (val === true) {
        this.$v.form.$touch();
      }
    },
  },
  mounted() {
    let vm = this;
    vm.updateNextButton(!this.$v.$invalid);

    EventBus.$on("reload-content-0", function () {
      vm.updateNextButton();
    });
  },
  methods: {
    updateNextButton(isValid) {
      if (isValid) {
        this.$emit("can-continue", { value: true });
        EventBus.$emit("form-update", this.form);
      } else {
        this.$emit("can-continue", { value: false });
      }
    },
    decreaseTemp() {
      let temp = parseFloat(this.form.bodyTemp);
      temp -= 0.1;
      if (temp <= 33.0) {
        temp = 33.0;
      }
      this.form.bodyTemp = temp.toFixed(1);
    },
    increaseTemp() {
      let temp = parseFloat(this.form.bodyTemp);
      temp += 0.1;
      if (temp >= 40.8) {
        temp = 40.8;
      }
      this.form.bodyTemp = temp.toFixed(1);
    },
    generateStatus() {
      let temp = parseFloat(this.form.bodyTemp);
      if (temp >= 36.5 && temp <= 37.4) {
        this.form.bodyTempStat = "NORMAL";
        this.tempFormat = "normal";
      } else if (temp >= 37.5 && temp <= 40.8) {
        this.form.bodyTempStat = "FEVER";
        this.tempFormat = "fever";
      } else if (temp < 36.5) {
        this.form.bodyTempStat = "HYPOTHERMIA";
        this.tempFormat = "hypothermia";
      }
    },
  },
};
</script>

<style scoped>
.hypothermia {
  color: #f7cc62;
  text-align: center;
  font-weight: bold;
}

.fever {
  color: #b00020;
  text-align: center;
  font-weight: bold;
}

.normal {
  color: #67b92f;
  text-align: center;
  font-weight: bold;
}
</style>
