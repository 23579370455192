<template>
  <b-container fluid>
    <b-row class="mt-4" no-gutters>
      <b-col sm="6" offset-sm="1">
        <b-form-group label="Purpose of Visit" v-slot="{ ariaDescribedby }">
          <b-form-radio
            class="my-1"
            v-for="opt in options"
            :key="opt"
            v-model="purposeOfVisit"
            :aria-describedby="ariaDescribedby"
            :name="opt"
            :value="opt"
          >
            {{ opt }}
          </b-form-radio>
        </b-form-group>
        <b-row no-gutters>
          <b-col v-if="purposeOfVisit === 'Others'" sm="12" md="6">
            <b-form-input
              type="text"
              size="sm"
              v-model="others"
              placeholder="Please specify"
            >
            </b-form-input>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import EventBus from "@/shared/event-bus";
import _ from "lodash";

export default {
  name: "Step3",
  props: ["clickedNext", "currentStep"],
  mixins: [validationMixin],
  data() {
    return {
      purposeOfVisit: "",
      form: {
        purposeOfVisit: "",
      },

      options: [
        "Worker/Staff",
        "Delivery",
        "Official Work Order",
        "Supplier",
        "Applicant",
        "Others",
      ],
      others: "",
    };
  },
  validations: {
    form: {
      purposeOfVisit: {
        required,
      },
    },
  },
  watch: {
    purposeOfVisit: function () {
      this.reflectOnPurposeOfVisit();
    },
    others: function () {
      if (this.purposeOfVisit === "Others" && _.isEmpty(this.others)) {
        this.$emit("can-continue", { value: false });
      } else {
        this.reflectOnPurposeOfVisit();
      }
    },
    $v: {
      handler: function (val) {
        this.updateNextButton(!val.$invalid);
      },
      deep: true,
    },
    clickedNext(val) {
      if (val === true) {
        this.$v.form.$touch();
      }
    },
  },
  mounted() {
    let vm = this;
    vm.updateNextButton(!this.$v.$invalid);

    EventBus.$on("reload-content-2", function () {
      vm.updateNextButton(!vm.$v.$invalid);
    });
  },
  methods: {
    updateNextButton(isValid) {
      if (isValid) {
        if (this.purposeOfVisit === "Others" && _.isEmpty(this.others)) {
          this.$emit("can-continue", { value: false });
        } else {
          this.$emit("can-continue", { value: true });
          EventBus.$emit("form-update", this.form);
        }
      } else {
        this.$emit("can-continue", { value: false });
      }
    },
    reflectOnPurposeOfVisit() {
      this.form.purposeOfVisit = this.purposeOfVisit;

      if (this.purposeOfVisit === "Others" && !_.isEmpty(this.others)) {
        this.form.purposeOfVisit = this.others;
      }
    },
  },
};
</script>

<style scoped>
</style>
