<template>
  <b-container fluid>
    <b-row class="mt-4" no-gutters>
      <b-col sm="11" offset-sm="1" class="mt-2 mb-4">
        <span class="section-question">
          Do you have any of the following symptoms?
        </span>
      </b-col>
      <b-col sm="11" offset-sm="1">
        <span class="section-title">List of Symptoms</span>
        <b-form-group label="">
          <b-form-checkbox
            class="my-1"
            v-for="opt in listOfSymptoms"
            :key="opt.name"
            v-model="opt.selected"
            value="true"
            unchecked-value="false"
          >
            <span>
              <b-img
                width="40px"
                height="40px"
                :src="'img/symptoms/' + opt.img + '.svg'"
                alt="Responsive image"
              >
              </b-img>
            </span>
            {{ opt.name }}
            <span v-show="opt.details.length > 0" class="translation">
              ({{ opt.details }})
            </span>
          </b-form-checkbox>
        </b-form-group>

        <span class="section-title">Others</span>
        <b-form-group label="">
          <b-form-checkbox
            class="my-1"
            v-model="noSymptoms.selected"
            value="true"
            unchecked-value="false"
          >
            {{ noSymptoms.name }}
            <span v-show="noSymptoms.details.length > 0" class="translation">
              ({{ noSymptoms.details }})
            </span>
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import EventBus from "@/shared/event-bus";
import _ from "lodash";

export default {
  name: "Step4",
  props: ["clickedNext", "currentStep"],
  mixins: [validationMixin],
  data() {
    return {
      form: {
        symptoms: ["no symptoms"],
      },
      listOfSymptoms: [
        {
          name: "Fever or Chills for the past few days",
          details: "Lagnat",
          img: "fever",
          selected: false,
        },
        {
          name: "Dry and persistent cough",
          details: "Ubo",
          img: "cough",
          selected: false,
        },
        {
          name: "Pain in muscles",
          details: "Pananakit ng katawan",
          img: "muscle_pain",
          selected: false,
        },
        {
          name: "Sore throat",
          details: "Pamamaga ng lalamunan",
          img: "sore_throat",
          selected: false,
        },
        {
          name: "Diarrhea",
          details: "Pagtatae",
          img: "diarrhea",
          selected: false,
        },
        {
          name: "Conjunctivitis",
          details: "Pamumula ng mata",
          img: "conjuctivitis",
          selected: false,
        },
        {
          name: "Loss of taste",
          details: "Kawalan ng panlasa",
          img: "loss_of_taste",
          selected: false,
        },
        {
          name: "Loss of smell",
          details: "Kawalan ng pang-amoy",
          img: "loss_of_smell",
          selected: false,
        },
        {
          name: "Colds",
          details: "Sipon",
          img: "colds",
          selected: false,
        },
        {
          name: "Difficulty of breathing",
          details: "Hirap sa paghinga",
          img: "difficulty_breathing",
          selected: false,
        },
        {
          name: "Headache",
          details: "Pananakit ng ulo",
          img: "headache",
          selected: false,
        },
        {
          name: "Discoloration of fingers and toes",
          details: "Pag-iiba ng kulay ng daliri sa kamay at paa",
          img: "discoloration",
          selected: false,
        },
        {
          name: "Rashes",
          details: "Pamamantal",
          img: "rashes",
          selected: false,
        },
      ],
      noSymptoms: {
        name: "no symptoms",
        details: "",
        selected: true,
      },
    };
  },
  validations: {
    form: {
      symptoms: {
        required,
      },
    },
  },
  watch: {
    listOfSymptoms: {
      handler: function (val) {
        this.reflectOnSymptoms();
      },
      deep: true,
    },
    noSymptoms: {
      handler: function (val) {
        // If the no symptoms is selected
        if (this.noSymptoms.selected === "true") {
          this.form.symptoms = [];
          this.form.symptoms.push(this.noSymptoms.name);

          // reset
          this.listOfSymptoms.forEach((item) => {
            item.selected = "false";
          });
        }
      },
      deep: true,
    },
    $v: {
      handler: function (val) {
        this.updateNextButton(!val.$invalid);
      },
      deep: true,
    },
    clickedNext(val) {
      if (val === true) {
        this.$v.form.$touch();
      }
    },
  },
  validations: {
    form: {
      symptoms: {
        required,
      },
    },
  },
  mounted() {
    let vm = this;
    vm.updateNextButton(!this.$v.$invalid);

    EventBus.$on("reload-content-3", function () {
      vm.updateNextButton(!vm.$v.$invalid);
    });
  },
  methods: {
    reflectOnSymptoms() {
      let symptoms = [];

      this.listOfSymptoms.forEach((item) => {
        if (item.selected === "true") {
          symptoms.push(item.name);
        }
      });

      if (symptoms.length > 0) {
        this.noSymptoms.selected = "false";
        this.form.symptoms = symptoms;
      } else {
        this.noSymptoms.selected = "true";
      }
    },
    updateNextButton(isValid) {
      if (isValid) {
        this.$emit("can-continue", { value: true });
        EventBus.$emit("form-update", this.form);
      } else {
        this.$emit("can-continue", { value: false });
      }
    },
  },
};
</script>

<style scoped>
.section-question {
  font-weight: bold;
  font-size: 15px;
}

.section-title {
  text-transform: capitalize;
  font-size: 14px;
}
.translation {
  font-style: italic;
  font-size: 13px;
  color: #b00020;
}
</style>
