<template>
  <div class="container">
    <div v-if="!isUnassignedHub" class="row">
      <div class="col-lg-4 col-md-6 mb-4">
        <CheckInStatus />
      </div>
      <div class="col-lg-8 col-md-6">
        <history />
      </div>
    </div>
    <div v-else class="row">
      <div class="col-md-12">
        <b-alert variant="danger" show>
          <i class="fa fa-exclamation-triangle"></i>&nbsp; You have no assigned
          hub to check-in at the moment. Please contact your account manager for
          the setup.
        </b-alert>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import CheckInStatus from "./home/CheckInStatus";
import History from "./home/History.vue";
import store from "../store";

export default {
  name: "home",
  components: {
    CheckInStatus,
    History,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
      if (from.fullPath !== "/login") {
        store.dispatch("worklog/fetchAssignedHubs");
      }
    });
  },
  computed: {
    ...mapGetters("worklog", ["getCurrMainHub"]),
    isUnassignedHub() {
      return _.isEmpty(this.getCurrMainHub);
    },
  },
};
</script>